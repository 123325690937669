




import { Component, Vue } from "vue-property-decorator";
import { AuthForm } from "@/data/auth";

@Component
export default class AuthComponent extends Vue {
  form = new AuthForm();

  created() {
    if (
      !["login", "registration", "restore"].includes(this.$route.name as string)
    ) {
      this.$router.push({
        name: "login"
      });
    }
  }
}
